<template>
    <b-col>
        <b-container class="opacity-background">
            <b-row lg="11" class="alias-style px-3">{{ alias }}</b-row>
            <b-row class="text-header px-3">{{ $t('surveyCarousel.headerText') }}</b-row>
            <b-carousel
                id="carouselExampleControls"
                class="carousel slide"
                :interval="0"
                controls
            >
                <div class="carousel-inner">
                    <div
                        v-for="question in removeQuestionsWithoutRating(survey.surveyQuestions)"
                        :key="question.id"
                        class="carousel-item active"
                    >
                        <div class="carousel-caption">
                            <b-row class="question-title justify-content-center mb-4">
                                {{ question.questionText }}
                            </b-row>
                            <b-row>
                                <b-col lg="4">
                                    <b-row class="justify-content">
                                        <b-col lg="11" class="mb-3">{{ $t('surveyCarousel.yourVoteText') }}</b-col>
                                        <b-col lg="11">
                                            <img
                                                class="dynamic-answer-image"
                                                :src="activeAnswerImage(question.rating)"
                                                alt="question rating"
                                            >
                                        </b-col>
                                        <b-col lg="11 mt-3">
                                            {{ $t('surveyCarousel.allVotes') }} <span class="total-votes">{{ question.totalRatings }}</span>
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col>
                                    <b-row
                                        v-for="(totalRatingCount) in question.totalRatingCounts"
                                        :key="totalRatingCount.rating"
                                        class="vertical-align my-3"
                                    >
                                        <b-col cols="3">
                                            <img

                                                class="carousel-icons"
                                                :src="activeAnswerImage(totalRatingCount.rating)"
                                                alt="question rating"
                                            >
                                        </b-col>
                                        <b-col cols="6">
                                            <div class="progress">
                                                <div :class="`color_${totalRatingCount.rating - 1}`" role="progressbar" :style="{ width: 100 * (totalRatingCount.count / question.totalRatings) + '%' }" />
                                            </div>
                                        </b-col>
                                        <b-col cols="3">
                                            <span>{{ answerLabel(totalRatingCount.rating) }}</span>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                        </div>
                    </div>
                </div>
            </b-carousel>
        </b-container>
    </b-col>
</template>
<script>
    export default {
        props: {
            survey: {
                type: Object,
                default: () => {}
            }
        },
        data() {
            return {
                smileLabels: this.$t('surveyRoute.ratings')
            }
        },
        computed: {
            alias() {
                if (!this.survey.multilingualSurveys || !this.survey.multilingualSurveys[0]) {
                    return 'NO_TRANSLATION_ALIAS'
                }
                return this.survey.multilingualSurveys[0].config.alias
            },
            hasDynamicAnswers() {
                return !!this.survey.answerImages
            }
        },
        methods: {
            removeQuestionsWithoutRating(questions) {
                return questions.filter(question => question.rating > 0)
            },
            activeAnswerImage(answerRating) {
                return this.survey?.answerImages?.[answerRating]?.activeImageUrl || require(`@/assets/images/surveys/smile_${answerRating - 1}_selected.png`)
            },
            answerLabel(answerRating) {
                return this.survey.multilingualSurveys[0]?.config?.answers?.[answerRating]?.answerText || this.smileLabels[answerRating - 1]
            }
        }
    }
</script>
<style lang="scss">
    .carousel-inner {
        overflow: hidden;
    }
</style>

<style lang="scss" scoped>
.alias-style {
    color: var(--secondary-color);
    font-size: 27px;
    padding-top: 20px;
    text-align: left;
    margin-left: 20px;
    margin-right: 20px;
}

.total-votes {
    color: var(--secondary-color);
    font-weight: 700;
}

.text-header {
    color: var(--white);
    font-size: 14px;
    text-align: left;
    border-bottom: var(--white) solid 1px;
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 20px;
}

.opacity-background {
    background-color: rgb(0 0 0 / 40%);
}

.carousel-inner {
    min-height: 520px;
}

.dynamic-answer-image {
    max-width: 60px;
    width: 100%;
    height: auto;
}

.question-title {
    font-weight: 700;
    font-size: 16px;
}

.carousel-icons {
    width: 100%;
    max-width: 35px;
}

.carousel-caption {
    color: var(--white);
    top: 0%;
}

.vertical-align {
    display: flex;
    align-items: center;
    margin-bottom: 0;
}

.progress {
    background: var(--black);
}

.progress .color_0 { background: var(--survey-very-poor); }

.progress .color_1 { background: var(--survey-poor); }

.progress .color_2 { background: var(--survey-acceptable); }

.progress .color_3 { background: var(--survey-very-good); }

.progress .color_4 { background: var(--survey-excellent); }

@media screen and (min-width: 992px) {
    .carousel-inner {
        min-height: 400px;
    }
}
</style>
