<template>
    <svg
        id="Layer_1"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 90 90"
        style="enable-background: new 0 0 90 90;"
        xml:space="preserve"
    >

        <path
            class="svg"
            :class="{'selected': selected}"
            d="M45,6C23.5,6,6,23.5,6,45c0,21.5,17.5,39,39,39s39-17.5,39-39C84,23.5,66.5,6,45,6z M56,31c2.2,0,4,1.8,4,4 c0,2.2-1.8,4-4,4s-4-1.8-4-4C52,32.8,53.8,31,56,31z M34,31c2.2,0,4,1.8,4,4c0,2.2-1.8,4-4,4s-4-1.8-4-4C30,32.8,31.8,31,34,31z M61.6,59.6C58.4,66,52.1,70,45,70c-7.1,0-13.4-4-16.6-10.4l-0.7-1.3l2.7-1.3l2.7-1.3l0.7,1.3C35.9,61.3,40.2,64,45,64 s9.1-2.7,11.2-7.1l0.7-1.3l2.7,1.3l2.7,1.3L61.6,59.6z"
        />
    </svg>

</template>
<script>
    export default {
        props: {
            selected: {
                type: Boolean,
                default: false
            }
        }
    }
</script>
<style lang="scss" scoped>
.svg {
    fill: var(--disabled-color);
}

.svg.selected {
    fill: var(--survey-excellent);
}
</style>
