<template>
    <b-col>
        <div
            v-for="(question,index) in localSurvey.surveyQuestions"
            :key="question.id"
            class="question-container mb-1 p-2"
        >
            <b-row class="mt-2">
                <b-col lg="3" class="ml-2">
                    <div class="survey-text">{{ index + 1 }}. {{ question.questionText }}</div>
                </b-col>
                <b-col class="mx-2 mt-2">
                    <b-row>
                        <div class="survey-icons-container">
                            <div
                                v-for="(answer, answerKey) in question.answers"
                                :key="answerKey"
                                class="survey-icon-container"
                                @click="selectAnswer(question, answerKey)"
                            >
                                <img
                                    v-if="answer.selected && answer.activeImage"
                                    :src="answer.activeImage"
                                    class="answer-icon"
                                >
                                <img
                                    v-else-if="!answer.inactiveImage && answer.activeImage"
                                    :src="answer.activeImage"
                                    class="answer-icon"
                                    :class="{'inactive': !answer.selected}"
                                >
                                <img
                                    v-else-if="answer.inactiveImage"
                                    :src="answer.inactiveImage"
                                    class="answer-icon"
                                >
                                <component
                                    :is="componentType(answer)"
                                    v-else
                                    :selected="answer.selected"
                                    class="survey-icon"
                                />
                                <span class="answer-label">
                                    {{ answer.label }}
                                </span>
                            </div>
                        </div>
                    </b-row>
                </b-col>
            </b-row>
        </div>
    </b-col>
</template>
<script>
    import SurveySvg1 from '@/components/svg/SurveySVG_1'
    import SurveySvg2 from '@/components/svg/SurveySVG_2'
    import SurveySvg3 from '@/components/svg/SurveySVG_3'
    import SurveySvg4 from '@/components/svg/SurveySVG_4'
    import SurveySvg5 from '@/components/svg/SurveySVG_5'
    export default {
        components: {
            SurveySvg1,
            SurveySvg2,
            SurveySvg3,
            SurveySvg4,
            SurveySvg5
        },
        props: {
            survey: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                ratings: {},
                localSurvey: this.survey
            }
        },
        computed: {
            answerImages() {
                return this.localSurvey.answerImages || []
            },
            hasDynamicAnswers() {
                return !!this.localSurvey.answerImages
            }
        },
        created() {
            this.localSurvey = this.prepareAnswerObjects(this.localSurvey)
        },
        methods: {
            prepareAnswerObjects(tempSurvey) {
                tempSurvey.surveyQuestions.forEach((question) => {
                    question.answers = {}
                    if (!this.hasDynamicAnswers) {
                        question.answers = Array.from({ length: 5 }, (_, index) => ({
                            [index + 1]: {
                                questionId: question.id,
                                selected: false,
                                type: `SurveySvg${index + 1}`,
                                label: this.$t(`surveyRoute.ratings[${index}]`)
                            }
                        })).reduce((acc, obj) => ({ ...acc, ...obj }), {})
                    } else {
                        Object.entries(tempSurvey.answerImages).reduce((acc, imageAnswer) => {
                            acc[imageAnswer[0]] = {
                                questionId: question.id,
                                selected: false,
                                activeImage: imageAnswer[1].activeImageUrl || null,
                                inactiveImage: imageAnswer[1].inactiveImageUrl || null,
                                type: `SurveySvg${imageAnswer[0]}`,
                                label: tempSurvey.multilingualSurveys[0].config.answers[imageAnswer[0]]?.answerText || this.$t(`surveyRoute.ratings[${imageAnswer[0] - 1}]`)
                            }
                            return acc
                        }, question.answers)
                    }
                })
                return tempSurvey
            },
            componentType(answer) {
                switch (answer.type) {
                case 'SurveySvg1':
                    return SurveySvg1
                case 'SurveySvg2':
                    return SurveySvg2
                case 'SurveySvg3':
                    return SurveySvg3
                case 'SurveySvg4':
                    return SurveySvg4
                case 'SurveySvg5':
                    return SurveySvg5
                }
            },
            selectAnswer(question, answerIndex) {
                Object.values(question.answers).map((answer) => {
                    answer.selected = false
                })

                const answerToChange = question.answers[answerIndex]
                answerToChange.selected = !answerToChange.selected

                this.$forceUpdate()
                this.$emit('update-survey', this.localSurvey)

                this.ratings[question.id] = answerIndex
                if (Object.keys(this.ratings).length === this.survey.surveyQuestions.length) {
                    this.$emit('update-ratings', this.ratings)
                }
            }
        }
    }
</script>
<style lang="scss" scoped>
.img-box img {
    width: 35px;
}

.img-box p {
    text-align: center;
}

.survey-logo {
    width: 25px;
}

.cursor-helper {
    cursor: pointer;
}

.survey-icons-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 0.5rem;
    width: 100%;
    padding: 0.5rem;
}

.survey-icon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}

.survey-icon {
    width: 11vw;
    height: 11vw;
    max-width: 60px;
    max-height: 60px;
}

.answer-icon {
    max-width: 70px;
    width: 100%;
    height: auto;
}

.survey-icon.inactive,
.answer-icon.inactive {
    filter: grayscale(100%);
}

.smile-labels {
    font-size: 2.5vw;
    text-align: left;
}

.answer-label {
    font-size: 1rem;
    margin-top: 0.25rem;
}

.survey-text {
    font-weight: 700;
    text-align: left;
}

.question-container {
    background: rgb(0 0 0 / 30%);
    color: var(--white);
}

@media screen and (min-width: 700px) {
    .survey-icons-container {
        padding: 0.5rem;
        gap: 1rem;
    }

    .survey-icon {
        padding: 0.5rem;
    }

    .smile-labels {
        font-size: 0.8rem;
    }
}

@media screen and (min-width: 992px) {
    .survey-icon {
        width: 70px;
        height: 70px;
    }

    .answer-icon {
        max-width: 80px;
    }
}

</style>
