<template>
    <svg
        id="Layer_1"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 90 90"
        style="enable-background: new 0 0 90 90;"
        xml:space="preserve"
    >

        <path
            class="svg"
            :class="{'selected': selected}"
            d="M45,6C23.5,6,6,23.5,6,45s17.5,39,39,39s39-17.5,39-39S66.5,6,45,6z M30,35c0-2.2,1.8-4,4-4s4,1.8,4,4 s-1.8,4-4,4S30,37.2,30,35z M56.8,65.7C53.5,68.5,49.3,70,45,70c-4.4,0-8.6-1.6-11.9-4.4l-1.1-1l3.9-4.6l1.1,1c2.2,1.9,5.1,3,8,3 c2.9,0,5.7-1,7.9-2.9l1.2-1l3.9,4.6C57.9,64.7,56.8,65.7,56.8,65.7z M56,39c-2.2,0-4-1.8-4-4s1.8-4,4-4s4,1.8,4,4S58.2,39,56,39z"
        />
    </svg>
</template>
<script>
    export default {
        props: {
            selected: {
                type: Boolean,
                default: false
            }
        }
    }
</script>
<style lang="scss" scoped>
.svg {
    fill: var(--disabled-color);
}

.svg.selected {
    fill: var(--survey-very-good);
}
</style>
